import $ from 'jquery';

export default {
	init() {

		$('.post-image a').attr('aria-hidden', 'true');

		$('#category-select #cat').on('change', function () {
			//this.form.submit();
		});

		$('.how-it-works-grid p').matchHeight({byRow: false});

	},
};
