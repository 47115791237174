import $ from 'jquery';

// jquery match height NMP
import jqueryMatchHeight from 'jquery-match-height';

import general from './modules/general';


document.addEventListener('DOMContentLoaded', function () {

	general.init();
	//fancyBox.init();
}, false);